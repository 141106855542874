import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { useCallback, useContext } from 'react';
import LabeledDropZone from '../../files/LabeledDropZone';
import ProductsRolesSelect from '../roles/ProductsRolesSelect';
import { PlaylistContext } from './PlaylistContext';
import castleThumbnailFiles from './CastleThumbnails';
import { useParams } from 'react-router';
import { getSourceUrl } from '../../../integrations/cloudinary';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

export default function BasicData({ children }) {
  const [state, dispatch] = useContext(PlaylistContext);

  const { typeName } = useParams();

  const castleThumbnail = state.type
    ? castleThumbnailFiles(state.type)
    : castleThumbnailFiles(typeName);

  const inputChange = useCallback(
    (event, metadata) => {
      let key = event.target.name;
      let value =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;

      if (metadata && metadata.thumbs) {
        key = 'thumbnailsFiles';
        value = { [event.target.name]: event.target.value };
      }
      dispatch({
        type: 'CHANGE_INPUT',
        payload: {
          [key]: value,
        },
      });
    },
    [dispatch]
  );

  if (!state.type) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <TextField
          required
          value={state.name}
          onChange={inputChange}
          name="name"
          label="Nome"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.freeOnly}
              onChange={inputChange}
              name="freeOnly"
              onClick={(event) => event.stopPropagation()}
            />
          }
          label="Mostrar apenas para usuários free"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          name="description"
          value={state.description}
          onChange={inputChange}
          label="Sinopse"
          multiline
          maxRows={10}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormHelperText>
          Texto com destaque na página de detalhes da nova plataforma e app.
        </FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <ProductsRolesSelect
          name="roles"
          selectedRoles={state.roles}
          inputChange={inputChange}
          label="Produtos com acesso"
          multiline
          maxRows={7}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            required
            label="Data de Lançamento na plataforma BP"
            value={state.releaseDate}
            autoOk={true}
            onChange={(value) => {
              inputChange({ target: { name: 'releaseDate', value } });
            }}
            helperText="Data de disponibilização para os membros"
            format="dd/MM/yyyy HH:mm"
            variant="inline"
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            required
            label="Data de Lançamento da produção."
            value={state.productionReleaseDate}
            autoOk={true}
            onChange={(value) => {
              inputChange({ target: { name: 'productionReleaseDate', value } });
            }}
            helperText="Data de lançamento do título no mercado. Data que será mostrada na tela de detalhe do título."
            format="dd/MM/yyyy HH:mm"
            variant="inline"
            ampm={false}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {state?.rating?.url && (
        <Grid item xs={12} sm={6}>
          <img
            src={`${state.rating.url}`}
            width="40px"
            height="40px"
            alt="Classificação indicativa"
            loading="lazy"
          />

          <FormHelperText>
            Classificação indicativa da playlist.
            <br />
            Esse valor é computado
          </FormHelperText>
        </Grid>
      )}

      {children}

      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          name="heroFile"
          width={310.5}
          height={266.25}
          initialFile={state.heroFile}
          inputChange={inputChange}
          maxWidth={310.5}
          label="Imagem de Capa (Mobile App)"
          helperText="Imagem mostrada no topo da página de detalhes do app. Dimensões: 1242x1065px"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          name={'dvdFile'}
          width={200}
          height={287}
          initialFile={state.dvdFile}
          inputChange={inputChange}
          maxWidth={200}
          label="Miniatura DVD"
          helperText={`Imagem mostrada em destaques e páginas de listagem no app, plataforma web e tv. Proporção: 11:16`}
        />
      </Grid>
      {castleThumbnail?.stateField !== 'dvd' && (
        <Grid item xs={12} lg={6}>
          <LabeledDropZone
            name={castleThumbnail?.stateField}
            width={castleThumbnail?.preview.width}
            height={castleThumbnail?.preview.height}
            initialFile={
              state.thumbnailsFiles
                ? state.thumbnailsFiles[castleThumbnail?.stateField]
                : ''
            }
            inputChange={(event) => inputChange(event, { thumbs: true })}
            maxWidth={castleThumbnail?.preview.width}
            label={
              castleThumbnail?.label
                ? castleThumbnail.label
                : 'Miniatura Nova Plataforma'
            }
            helperText={`Imagem mostrada no app e na nova plataforma web. Dimensões: ${castleThumbnail?.proportion.width}x${castleThumbnail?.proportion.height}px`}
          />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          name="heroWeb"
          width={480}
          height={270}
          initialFileUrl={
            state.heroWeb?.image ? getSourceUrl(state.heroWeb) : ''
          }
          initialFile={state.heroWeb?.image ? undefined : state.heroWeb}
          inputChange={inputChange}
          maxWidth={480}
          label="Imagem de Capa Nova Plataforma"
          helperText="Imagem mostrada na página de detalhes da nova plataforma web. Dimensões: 1920x1080px"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          name="playlistLogo"
          width={400}
          height={230}
          initialFileUrl={
            state.playlistLogo?.image ? getSourceUrl(state.playlistLogo) : ''
          }
          initialFile={
            state.playlistLogo?.image ? undefined : state.playlistLogo
          }
          inputChange={inputChange}
          maxWidth={400}
          label="Logo da Playlist (opcional)"
          helperText="Utilizar uma imagem de logo é uma forma mais atrativa de apresentarmos o conteúdo na plataforma. Se não cadastrado, é mostrado o texto do título. Obs.: utilizar fundo transparente. Dimensões máximas: 1200x690px"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          name="coverFile"
          width={560}
          height={200}
          initialFile={state.coverFile}
          inputChange={inputChange}
          maxWidth={800}
          label="Imagem de capa"
          helperText="Imagem utilizada como destaque de última produção (plataforma antiga)."
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          maxWidth={480}
          width={480}
          height={270}
          name="thumbnailFile"
          initialFile={state.thumbnailFile}
          inputChange={inputChange}
          label="Miniatura secundária (multi-tipo)"
          helperText="Miniatura usada em todos os clientes para carrosséis com playlists de diferentes tipos."
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <LabeledDropZone
          maxWidth={560}
          height={150}
          name="freemium_banner_img"
          initialFileUrl={
            state.freemium_banner_img?.image
              ? getSourceUrl(state.freemium_banner_img)
              : ''
          }
          initialFile={
            state.freemium_banner_img?.image
              ? undefined
              : state.freemium_banner_img
          }
          inputChange={inputChange}
          label="Imagem de fundo para banner freemium"
          helperText="Imagem utilizada como banner para a playlist freemium."
        />
      </Grid>
    </Grid>
  );
}
