import api from '../../api';

export const getUserById = (id) =>
  api
    .get(`/users/${id}`)
    .then((response) => response.data)
    .catch((err) => console.log(err));

export const updateUser = (data) =>
  api
    .put(`/admin/users`, data)
    .then((response) => response.data)
    .catch((error) => {
      alert('Não foi possível atualizar o usuário.');
    });

export const createUser = (data) =>
  api
    .post('/admin/users', data)
    .then((response) => response.data)
    .catch((error) => {
      alert('Não foi possível cadastrar o usuário.');
    });

export const createUserSubscription = (data) =>
  api
    .post(`/admin/users/${data.userId}/subscriptions`, data)
    .then((response) => response.data)
    .catch((error) => {
      alert('Não foi possível cadastrar o usuário.');
    });

export const setAdminPrivileges = (data) =>
  api
    .put(`/admin/users/admin-privileges`, data)
    .then((response) => response.data)
    .catch((error) => {
      alert('Não foi possível configurar permissões para o usuário');
    });

export const editUserRole = (userId, roleName, hasRole) =>
  api
    .put(`/admin/users/role`, { userId, roleName, hasRole })
    .then((response) => response.data)
    .catch((error) => {
      alert('Não foi possível configurar permissões para o usuário');
    });
