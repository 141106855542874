import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core/';

import ConfirmationDialog from './confirmationDialog';
import PageTitle from '../../app/main/Title';
import { asyncUpdateOffer } from '../../../redux/offer/offer.actions';
import { getOfferSaleLink } from '../../../utils/offer';
import ProductSelect from '../products/ProductSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2rem 0',
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%',
    margin: 0,
  },
  editableField: {
    color: 'red',
  },
  copyBtn: {
    position: 'absolute',
    top: 18,
    right: 18,
  },
}));

const brokerNames = {
  guru: 'Guru',
};

const offerInterval = {
  day: 'Diária',
  week: 'Semanal',
  month: 'Mensal',
  year: 'Anual',
};

const supportedCurrencies = [
  { value: 'BRL', label: 'Real (BRL)' },
  { value: 'USD', label: 'Dólar Norte Americano (USD)' },
  { value: 'EUR', label: 'Euro (EUR)' },
];

export default ({ toUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [oldOfferData] = useState(
    toUpdate || {
      name: '',
      broker_name: '',
      broker_product_id: '',
      broker_offer_id: '',
      interval: 'year',
      interval_count: '',
      max_cycles: '',
      discount: '',
      amount: '',
      currency: '',
      product: {
	_id: '',
	name: '',
      },
      active: false,
      default: false,
    }
  );

  const [newOfferData, setNewOfferData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = (key, value) => {
    if (oldOfferData[key] === value) {
      setNewOfferData(_.omit(newOfferData, [key]));
    } else {
      setNewOfferData((offer) => ({
        ...offer,
        [key]: value,
      }));
    }
  };

  const [currencyLabel, setCurrencyLabel] = useState('Moeda');
  const handleCurrencyLabel = (value) => {
    const currencyLabel = supportedCurrencies.find((o) => o.value === value)
      .label;
    setCurrencyLabel(currencyLabel);
  };

  const [offerSaleLink, setOfferSaleLink] = useState(
    getOfferSaleLink(oldOfferData._id, oldOfferData.product.name)
  );

  const [copySuccess, setCopySuccess] = useState('Copiar');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(offerSaleLink);
    setCopySuccess('Copiado!');
    setTimeout(() => setCopySuccess('Copiar'), 1000);
  };

  useEffect(() => {
    if (Object.keys(newOfferData).length > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }

    if (toUpdate) {
      setOfferSaleLink(getOfferSaleLink(toUpdate._id, toUpdate.product.name));
    }
  }, [newOfferData, toUpdate]);

  const keyToName = (key) => {
    const keyNames = {
      name: 'Nome da oferta',
      broker_name: 'Nome do broker',
      broker_product_id: 'Identificador do produto',
      broker_offer_id: 'Identificador da oferta',
      interval: 'Frequência',
      interval_count: 'Intervalo de cobrança',
      max_cycles: 'Máximo de ciclos',
      discount: 'Desconto',
      amount: 'Valor',
      currency: 'Moeda',
    };

    return keyNames[key] || key;
  };

  const openConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(true);
  };

  const closeConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog(false);

    dispatch(asyncUpdateOffer({
      id: oldOfferData._id,
      product: oldOfferData.product._id,
      ...newOfferData,
    }));
    
    setTimeout(() => window.location.reload(), 500);
  };

  const onProductChange = (value) => handleChange('product', value);

  return (
    <>
      <PageTitle>Atualizar oferta</PageTitle>

      <form method="PUT" onSubmit={openConfirmDialog}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textField}
                label="Identificador do produto"
                defaultValue={oldOfferData.broker_product_id}
                name="broker_product_id"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textField}
                label="Identificador da oferta"
                defaultValue={oldOfferData.broker_offer_id}
                name="broker_offer_id"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                className={classes.textField}
                label="Nome da oferta"
                defaultValue={oldOfferData.name}
                name="name"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                className={classes.textField}
                label="Nome do broker"
                defaultValue={brokerNames[oldOfferData.broker_name]}
                name="broker_name"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <ProductSelect
                onChange={onProductChange}
                value={oldOfferData.product?._id}
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                className={classes.textField}
                label="Frequência"
                defaultValue={offerInterval[oldOfferData.interval]}
                name="interval"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <TextField
                className={classes.textField}
                label="Intervalo de cobrança"
                defaultValue={oldOfferData.interval_count}
                name="interval_count"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <TextField
                className={classes.textField}
                label="Máximo de ciclos"
                defaultValue={oldOfferData.max_cycles}
                name="max_cycles"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 0,
                  step: '0.01',
                  type: 'number',
                }}
                className={classes.textField}
                label="Valor"
                defaultValue={oldOfferData.amount / 100}
                name="amount"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  min: 0,
                  step: '0.01',
                  type: 'number',
                }}
                className={classes.textField}
                label="Desconto"
                defaultValue={oldOfferData.discount.amount / 100}
                name="discount"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <TextField
                className={classes.textField}
                label="Máximo de ciclos"
                defaultValue={oldOfferData.discount.cycles}
                name="discount_cycles"
                disabled={true}
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel>Moeda*</InputLabel>
                <Select
                  defaultValue={oldOfferData.currency}
                  label={currencyLabel}
                  name="currency"
                  required={true}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                    handleCurrencyLabel(e.target.value);
                  }}
                >
                  {supportedCurrencies.map((c) => (
                    <MenuItem key={c.value} value={c.value}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ position: 'relative' }}>
              <TextField
                className={classes.textField}
                label={`Link para compra`}
                value={offerSaleLink}
                variant="outlined"
                name="broker_product_link"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                type="button"
                className={classes.copyBtn}
                onClick={copyToClipboard}
              >
                {copySuccess}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    defaultChecked={oldOfferData.active}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Ativa"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="default"
                    defaultChecked={oldOfferData.default}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Padrão"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!canSubmit}
              >
                {toUpdate ? 'Atualizar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>

      {confirmDialog && (
        <ConfirmationDialog
          onCancel={closeConfirmDialog}
          onConfirm={handleSubmit}
          title="Os seguintes campos serão alterados:"
          body={Object.keys(newOfferData)
            .map(keyToName)
            .join(', ')}
          cancelText="Cancelar"
          confirmText="Atualizar"
        />
      )}
    </>
  );
};
