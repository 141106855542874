import React from 'react';
import api from '../../../api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
} from '@material-ui/core';

import PageTitle from '../../../components/app/main/Title';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Users() {
  const [product, setProduct] = React.useState('');
  const daysInputId = 'days-input-number';
  const fileInputId = 'file-input-name';
  const [snackOpen, setOpen] = React.useState(false);
  const [processConfirmed, setConfirmationBar] = React.useState(false);
  const [processDenied, setDeniedBar] = React.useState(false);
  const [snackErrorMessage, setSnackErrorMessage] = React.useState(
    'Acao invalida'
  );
  const [executeActionDebounce, setDebounce] = React.useState(false);
  const promotionActionDebounceTime = 2000;

  const handleSnackBarClose = async (event, reason) => {
    if (reason === 'clickaway') {
      setOpen(false);
      return;
    }
    setOpen(false);
    return await processAction();
  };
  const handleSnackBarOpen = () => {
    setOpen(true);
  };

  const processAction = async () => {
    let days = document.getElementById(daysInputId).value;
    let file = document.getElementById(fileInputId).files[0];
    if (days === undefined || days <= 0) {
      setSnackErrorMessage(
        `Cannot create subscriptions for 0 days. Check inputs.`
      );
      setDeniedBar(true);
      return;
    }
    if (file === undefined) {
      setSnackErrorMessage(`No valid CSV found. Check inputs.`);
      setDeniedBar(true);
      return;
    }
    if (product === undefined || product === '' || product === ' ') {
      setSnackErrorMessage(`Invalid product. Check inputs.`);
      setDeniedBar(true);
      return;
    }
    let formData = new FormData();
    formData.append('file', file);
    formData.append('plan', product);
    formData.append('days', days);
    setDebounce(true);
    api
      .post('admin/users/upload-promotion-action', formData)
      .then((response) => {
        console.log(response);
        setTimeout(() => {
          setDebounce(false);
          setConfirmationBar(true);
          document.getElementById(daysInputId).value = 0;
          let fileHtmlInput = document.getElementById(fileInputId);
          fileHtmlInput.value = '';
        }, promotionActionDebounceTime);
      });
  };

  const snackBarAction = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        Confirm
      </Button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <PageTitle>Reativação promocional</PageTitle>
      Para criar uma ação de reativação de usuários, carregue um arquivo csv com
      os e-mails dos usuários inativos que se deseja reativar. Além disso,
      insira o número de dias que deseja que a promoção dure e o tipo de
      assinatura concedida.
      <div
        style={{
          display: 'flex-row',
        }}
      >
        <div style={{ display: 'flex', gap: `20px` }}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel>Assinatura</InputLabel>
              <Select
                id="plan-select-id"
                label="Assinatura"
                defaultValue={''}
                onChange={(event) => {
                  setProduct(event.target.value);
                }}
              >
                <MenuItem value={'5f1dd789def504001732354e'}>Fraterno</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <TextField
                id={daysInputId}
                label="Dias disponíveis"
                type="number"
              />
            </FormControl>
          </Box>
        </div>
        <div
          style={{
            marginTop: `20px`,
            display: 'flex',
          }}
        >
          <input
            id={fileInputId}
            fullWidth
            label="Arquivo CSV"
            type="file"
            accept=".csv"
          ></input>

          <Button
            disabled={executeActionDebounce}
            variant="contained"
            color="primary"
            style={{ marginLeft: `auto` }}
            onClick={(event) => {
              handleSnackBarOpen();
            }}
          >
            Iniciar ação
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackOpen}
            message={`Prosseguir com os dados fornecidos?`}
            onClose={handleSnackBarClose}
            action={snackBarAction}
          ></Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={processConfirmed}
            autoHideDuration={2500}
            onClose={() => {
              setConfirmationBar(false);
            }}
          >
            <Alert
              onClose={() => {
                setConfirmationBar(false);
              }}
              severity="success"
              sx={{ width: '100%' }}
            >
              Reativacao Promocional executada com sucesso
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={processDenied}
            message={snackErrorMessage}
            autoHideDuration={5000}
            onClose={() => {
              setDeniedBar(false);
            }}
          ></Snackbar>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Users;
