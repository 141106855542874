import UsersPage from '../../../pages/admin/users';
import PromotionActionsPage from '../../../pages/admin/promotionActions';
import GeoIpManagerPage from '../../../pages/admin/geo-ip-manager';
import UsersImportPage from '../../../pages/admin/users/import';

import {
  UserCreateHandler,
  UserUpdateHandler,
} from '../../admin/users/FormHandler';
export default [
  {
    link: '/admin/users',
    component: UsersPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/create',
    component: UserCreateHandler,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/import',
    component: UsersImportPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/details/:id',
    component: UserUpdateHandler,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/promotion-actions',
    component: PromotionActionsPage,
    auth: true,
    roles: ['admin'],
  },
  {
    link: '/admin/users/geo-ip-manager',
    component: GeoIpManagerPage,
    auth: true,
    roles: ['admin'],
  },
];
