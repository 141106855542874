import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Snackbar,
  Icon,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardBackspace, Save } from '@material-ui/icons';

import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { getBanner, patchMainBanner } from '../../../integrations/api/banners';
import { getAllPlaylists } from '../../../integrations/api/playlists';
import { uploadImage } from '../../../integrations/api/files';
import { getSourceUrl } from '../../../integrations/cloudinary';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: 300,
  },
  editableField: {
    color: 'red',
  },
  form: {
    marginTop: '20px',
    '& .MuiTextField-root': {
      width: '80%',
    },
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  durationLabel: {
    textAlign: 'center',
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #ddd',
  },
}));

function FormBanner() {
  const history = useHistory();

  const { id } = useParams();
  const classes = useStyles();

  const [bannerState, setBannerState] = useState({ visibility: 'all' });
  const [loading, setLoading] = useState(Boolean(id));
  const [visibility, setVisibility] = useState('all');
  const [image, setImage] = useState('');
  const [mobileImage, setMobileImage] = useState('');
  const [logo, setLogo] = useState('');
  const [end, setEnd] = useState(new Date());
  const [start, setStart] = useState(new Date());

  const [showInHome, setShowInHome] = useState(true);
  const [showInMovies, setShowInMovies] = useState(false);
  const [showInShows, setShowInShows] = useState(false);

  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    if (playlists.length === 0)
      getAllPlaylists().then((response) => {
        setPlaylists(response.data);
      });

    if (id) {
      getBanner(id).then((response) => {
        const existentBanner = response.data;
        setBannerState(existentBanner);
        setVisibility(existentBanner.visibility);
        setEnd(existentBanner.end);
        setStart(existentBanner.start);
        setLoading(false);

        setShowInHome(existentBanner.showIn?.home);
        setShowInMovies(existentBanner.showIn?.movies);
        setShowInShows(existentBanner.showIn?.shows);
      });
    }
  }, [id, playlists]);

  const [snackbar, setSnackbar] = useState({ open: false });

  if (!playlists || playlists.length === 0) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!image && !bannerState.image) {
      setSnackbar({
        open: true,
        message: 'A imagem de fundo é um campo obrigatório!',
        type: 'error',
      });
      return;
    }

    if (!end && !bannerState.end) {
      setSnackbar({
        open: true,
        message: 'O período de ativação do banner deve ser configurado!',
        type: 'error',
      });
      return;
    }

    if (!bannerState.buttonLink) {
      setSnackbar({
        open: true,
        message: 'Você precisa selecionar um link para o botão do banner!',
        type: 'error',
      });
      return;
    }

    setLoading(true);

    if (!bannerState.start) bannerState.start = new Date();

    let imagesToUpload = [];
    if (image) {
      imagesToUpload.push({
        image: image.file,
        name: 'image',
        path: `/banners/main/image`,
      });
    }

    if (mobileImage) {
      imagesToUpload.push({
        image: mobileImage.file,
        name: 'mobileImage',
        path: `/banners/main/mobile/image`,
      });
    }

    if (logo) {
      imagesToUpload.push({
        image: logo.file,
        name: 'logo',
        path: `/banners/main/logo`,
      });
    }

    Promise.all(imagesToUpload.map((arg) => uploadImage(arg)))
      .then((responses) => {
        let banner = bannerState;
        banner.showIn = {
          home: showInHome,
          movies: showInMovies,
          shows: showInShows,
        };

        Object.assign(banner, ...responses.map(({ data }) => data.reference));

        setBannerState(banner);
        submitForm(banner);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Erro ao salvar os dados!',
          type: 'error',
        });
        setLoading(false);
        return;
      });
  };

  const submitForm = (bannerWithImages) => {
    patchMainBanner(bannerWithImages)
      .then(() => {
        setLoading(false);
        handleSuccess();
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Erro ao salvar os dados!',
          type: 'error',
        });
        setLoading(false);
        return;
      });
  };

  const handleSuccess = () => {
    setLoading(false);
    store.dispatch(
      openSnackBar({
        type: 'success',
        message: 'Dados atualizados com sucesso!',
      })
    );

    history.push('/admin/banners');
  };

  const handleDateChange = (key, value) => {
    bannerState[key] = value;
    setBannerState(bannerState);
    if (key === 'end') {
      setEnd(value);
    } else {
      setStart(value);
    }
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    bannerState[name] = value;
    setBannerState(bannerState);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
      <Typography variant="h4" gutterBottom>
        <Button
          onClick={() => {
            history.push('/admin/banners');
          }}
        >
          <KeyboardBackspace></KeyboardBackspace>
        </Button>
        Banner Principal
      </Typography>

      <Divider />

      <form method="PATCH" className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Conteúdo do Banner
          </Typography>

          <Divider />
          <br />

          <div style={{ marginBottom: '20px' }}>
            <FormLabel component="legend">Título</FormLabel>
            <FormHelperText>
              Título mostrado em destaque, no canto superior esquerdo. Você deve
              cadastrar uma imagem contendo um logo e o texto que poderá ser
              mostrado em seu lugar.
            </FormHelperText>
          </div>
          <Grid container style={{ height: '200px' }}>
            <Grid item xs={12} sm={4}>
              <TextField
                onChange={handleChange}
                className={classes.textField}
                label={`Título em texto`}
                name="title"
                defaultValue={bannerState.title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button startIcon={<Icon>upload</Icon>} component="label">
                Upload Imagem
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0])
                      setLogo({
                        url: URL.createObjectURL(e.target.files[0]),
                        file: e.target.files[0],
                      });
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src={
                  logo
                    ? logo.url
                    : bannerState.logo
                    ? getSourceUrl(bannerState.logo)
                    : ''
                }
                alt=""
                style={{
                  display: 'block',
                  maxWidth: '400px',
                  backgroundColor: 'rgb(40, 40, 40)',
                  padding: '24px',
                }}
              />
            </Grid>
          </Grid>

          <div style={{ marginBottom: '20px' }}>
            <FormLabel component="legend">Descrição do banner</FormLabel>
            <FormHelperText>
              Texto que fica abaixo do título. Sugestão: parágrafo de até x
              caracteres.
            </FormHelperText>
          </div>

          <TextField
            style={{ width: '60%' }}
            onChange={handleChange}
            className={classes.textField}
            defaultValue={bannerState.description}
            maxRows={4}
            multiline
            name="description"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div style={{ marginBottom: '20px' }}>
            <div style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">
                Imagem de fundo (dimensões: 1920x980px)
              </FormLabel>
              <FormHelperText>
                Imagem de fundo. O texto aparece do lado esquerdo da tela (do
                ponto de vista do usuário). Por isso o conteúdo relevante da
                imagem deve ficar localizado na área centro-direita.
              </FormHelperText>
            </div>

            <Grid container>
              <div style={{ marginRight: '30px' }}>
                <Button startIcon={<Icon>upload</Icon>} component="label">
                  Upload Imagem
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setImage({
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      }
                    }}
                  />
                </Button>
              </div>

              <div
                style={{
                  minHeight: '200px',
                }}
              >
                <img
                  src={
                    image
                      ? image.url
                      : bannerState.image
                      ? getSourceUrl(bannerState.image)
                      : ''
                  }
                  alt=""
                  style={{
                    objectFit: 'cover',
                    width: '640px',
                    height: '327px',
                  }}
                />
              </div>
            </Grid>
          </div>

          <Divider />
          <br />

          <div style={{ marginBottom: '20px' }}>
            <FormLabel component="legend">
              Imagem de fundo (Mobile) (dimensões: 1242x1065px)
            </FormLabel>
            <FormHelperText>
              Imagem de fundo utilizada no aplicativo. O texto fica centralizado
              na parte de baixo da imagem. O conteúdo relevante da imagem deve
              ficar na parte superior.
            </FormHelperText>
          </div>

          <Grid container>
            <Grid item xs={3}>
              <div style={{ marginRight: '30px' }}>
                <Button startIcon={<Icon>upload</Icon>} component="label">
                  Upload Imagem
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setMobileImage({
                          url: URL.createObjectURL(e.target.files[0]),
                          file: e.target.files[0],
                        });
                      }
                    }}
                  />
                </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  minHeight: '200px',
                }}
              >
                <img
                  src={
                    mobileImage
                      ? mobileImage.url
                      : bannerState.mobileImage
                      ? getSourceUrl(bannerState.mobileImage)
                      : ''
                  }
                  alt=""
                  style={{
                    objectFit: 'cover',
                    width: '310px',
                    height: '266px',
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Divider />
          <br />

          <div style={{ marginBottom: '20px' }}>
            <FormLabel component="legend">Botão</FormLabel>
            <FormHelperText>
              Todo banner deve ter pelo menos um botão, para direcionar o
              usuário a algum conteúdo.
            </FormHelperText>
          </div>

          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                onChange={handleChange}
                className={classes.textField}
                defaultValue={bannerState.buttonText}
                label={`Texto do Botão`}
                required
                name="buttonText"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                options={playlists}
                onChange={(event, value) => {
                  event.preventDefault();
                  handleChange({
                    target: {
                      name: 'buttonLink',
                      value: value ? value._id : '',
                    },
                  });
                }}
                autoComplete
                getOptionLabel={(option) => (option.name ? option.name : '')}
                name="buttonLink"
                defaultValue={
                  bannerState.buttonLink
                    ? playlists.find((p) => p._id === bannerState.buttonLink)
                    : {}
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Link para *"
                    name="buttonPlaylistLink"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Divider />
          <br />

          <div style={{ marginBottom: '20px' }}>
            <FormLabel component="legend">Segundo Botão (Opcional)</FormLabel>
            <FormHelperText>
              O segundo botão é opcional. Se cadastrado, aparecerá ao lado do
              primeiro botão.
            </FormHelperText>
          </div>

          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                onChange={handleChange}
                className={classes.textField}
                label={`Texto do Botão`}
                defaultValue={bannerState.secondButtonText}
                name="secondButtonText"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                onChange={handleChange}
                className={classes.textField}
                label="Link"
                name="secondButtonLink"
                defaultValue={bannerState.secondButtonLink}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <br />

          <Typography variant="h6" gutterBottom>
            Período de exibição
          </Typography>

          <Divider />
          <br />

          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  required
                  label="de"
                  value={start}
                  autoOk={true}
                  format="dd/MM/yyyy HH:mm"
                  variant="inline"
                  ampm={false}
                  onChange={(value) => {
                    handleDateChange('start', value);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  required
                  label="até"
                  value={end}
                  autoOk={true}
                  format="dd/MM/yyyy HH:mm"
                  variant="inline"
                  ampm={false}
                  onChange={(value) => {
                    handleDateChange('end', value);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <br />
          <br />

          <Typography variant="h6" gutterBottom>
            Páginas de exibição
          </Typography>

          <Divider />
          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={showInHome}
                onChange={() => {
                  setShowInHome(!showInHome);
                }}
              />
            }
            label="Home"
          />
          <FormControlLabel
            checked={showInMovies}
            control={<Checkbox />}
            onClick={() => {
              setShowInMovies(!showInMovies);
            }}
            label="Filmes"
          />
          <FormControlLabel
            checked={showInShows}
            control={<Checkbox />}
            onClick={() => {
              setShowInShows(!showInShows);
            }}
            label="Programas"
          />
        </div>

        <br />
        <br />

        <Typography variant="h6" gutterBottom>
          Configuração de Acesso
        </Typography>

        <Divider />
        <br />

        <div style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">Visibilidade</FormLabel>
          <FormHelperText>
            Você pode selecionar o grupo de usuários que poderá ver esse banner.
            Você pode liberar apenas para BPeiros primeiro para ver como fica na
            plataforma. Depois liberar para todos os usuários. Se quiser
            desativar um banner, basta marcar a opção 'Ninguém'. Se quiser que o
            banner apareça só para usuários free, marque a opção 'Somente free'.
          </FormHelperText>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <RadioGroup
            aria-label="visibility"
            name="visibility"
            onChange={handleChange}
          >
            <FormControlLabel
              value="nobody"
              checked={visibility === 'nobody'}
              control={<Radio />}
              onChange={(e) => {
                setVisibility('nobody');
                handleChange(e);
              }}
              label="Ninguém"
            />
            <FormControlLabel
              value="bpeiros"
              checked={visibility === 'bpeiros'}
              control={<Radio />}
              onChange={(e) => {
                setVisibility('bpeiros');
                handleChange(e);
              }}
              label="Bpeiros"
            />
            <FormControlLabel
              value="free-only"
              checked={visibility === 'free-only'}
              control={<Radio />}
              onChange={(e) => {
                setVisibility('free-only');
                handleChange(e);
              }}
              label="Somente free"
            />
            <FormControlLabel
              value="all"
              checked={visibility === 'all'}
              control={<Radio />}
              onChange={(e) => {
                setVisibility('all');
                handleChange(e);
              }}
              label="Todos"
            />
          </RadioGroup>
        </div>

        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Save />}
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
  );
}

export default FormBanner;
