import React, { createContext, useReducer } from 'react';
import { array } from '../../../utils/reducer';

export const PlaylistContext = createContext();

const initialState = {
  loading: false,
  name: '',
  description: '',
  modules: [],
  medias: [],
  thumbnail: null,
  cover: null,
  thumbnails: {},
  roles: [],
  heroWeb: undefined,
  directors: undefined,
  cast: undefined,
  playlist: '',
  playlistLogo: undefined,
  releaseDate: new Date(),
  productionReleaseDate: new Date(),
  exitDate: null,
  rating: null,
  materialsForm: {
    open: false,
    mediaIndex: 0,
    moduleIndex: undefined,
  },
  audioForm: {
    open: false,
    moduleIndex: undefined,
    mediaIndex: undefined,
    formType: undefined,
  },
  moduleThumbnail: {
    open: false,
    moduleIndex: undefined,
  },
  freeOnly: false,
  freemium_banner_img: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    case 'INITIAL_STATE':
      return {
        ...initialState,
        ...action.payload,
      };
    case 'ADD_MODULE':
      return {
        ...state,
        modules: array.add(state.modules, action.payload),
      };
    case 'SET_MODULES':
      return {
        ...state,
        modules: [...action.payload.modules],
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'SET_MEDIAS':
      if (action.payload.moduleIndex >= 0) {
        const { modules = [] } = state;
        modules[action.payload.moduleIndex].medias = action.payload.data;

        return {
          ...state,
          modules
        }
      } else {
        return {
          ...state,
          medias: [...action.payload.data],
        };
      }
    case 'REPLACE_MODULE':
      return {
        ...state,
        modules: array.replace(
          state.modules,
          action.payload.index,
          action.payload.data
        ),
      };
    case 'REPLACE_MODULE_THUMB':
      return {
        ...state,
        modules: state.modules.map((mod, index) =>
          index !== state.moduleThumbnail.moduleIndex
            ? mod
            : { ...mod, thumbnail: action.payload.thumbnail }
        ),
      };
    case 'OPEN_MODULE_THUMBNAIL': {
      return {
        ...state,
        moduleThumbnail: {
          moduleIndex: action.payload.moduleIndex,
          open: true,
        },
      };
    }
    case 'REPLACE_MEDIA':
      if (action.payload.moduleIndex >= 0) {
        return {
          ...state,
          modules: array.replaceNested(
            state.modules,
            action.payload.moduleIndex,
            'medias',
            action.payload.mediaIndex,
            action.payload.newMedia
          ),
        };
      }
      return {
        ...state,
        medias: array.replace(
          state.medias,
          action.payload.mediaIndex,
          action.payload.newMedia
        ),
      };
    case 'DEL_MODULE':
      return {
        ...state,
        modules: array.remove(state.modules, action.payload),
      };

    case 'ADD_MEDIA':
      if (action.payload.moduleIndex >= 0) {
        return {
          ...state,
          modules: array.addNested(
            state.modules,
            action.payload.moduleIndex,
            'medias',
            action.payload.newMedia
          ),
        };
      }
      return {
        ...state,
        medias: array.add(state.medias, action.payload.newMedia),
      };
    case 'REMOVE_MEDIA':
      if (action.payload.moduleIndex >= 0) {
        return {
          ...state,
          modules: array.removeNested(
            state.modules,
            action.payload.moduleIndex,
            'medias',
            action.payload.mediaIndex
          ),
        };
      }
      return {
        ...state,
        medias: array.remove(state.medias, action.payload.mediaIndex),
      };
    case 'CLOSE_MATERIALS': {
      return {
        ...state,
        materialsForm: {
          mediaIndex: null,
          moduleIndex: null,
          open: false,
        },
      };
    }
    case 'OPEN_MATERIALS': {
      return {
        ...state,
        materialsForm: {
          moduleIndex: action.payload.moduleIndex,
          mediaIndex: action.payload.mediaIndex,
          open: true,
        },
      };
    }
    case 'CLOSE_MODULE_THUMBNAIL': {
      return {
        ...state,
        moduleThumbnail: {
          open: false,
        },
      };
    }
    case 'OPEN_AUDIO': {
      return {
        ...state,
        audioForm: {
          moduleIndex: action.payload.moduleIndex,
          mediaIndex: action.payload.mediaIndex,
          formType: action.payload.formType,
          open: true,
        },
      };
    }
    case 'CLOSE_AUDIO': {
      return {
        ...state,
        audioForm: {
          open: false,
        },
      };
    }
    case 'ADD_MODULE_AUDIO':
      return {
        ...state,
        modules: state.modules.map((mod, i) => {
          if (i === action.payload.moduleIndex) {
            return {
              ...mod,
              audio: {
                name: `${mod.name} - audio`,
                sourceInput: action.payload.link,
              },
            };
          }
          return mod;
        }),
      };
    case 'ADD_MEDIA_AUDIO':
      return {
        ...state,
        medias: state.medias.map((med, i) => {
          if (i === action.payload.mediaIndex) {
            return {
              ...med,
              audio: {
                name: `${med.name} - audio`,
                sourceInput: action.payload.link,
              },
            };
          }
          return med;
        }),
      };
    case 'ADD_MATERIAL':
      if (state.materialsForm.moduleIndex >= 0) {
        if (
          state.materialsForm.mediaIndex !== null &&
          state.materialsForm.mediaIndex >= 0
        ) {
          return {
            ...state,
            modules: state.modules.map((mod, i) => {
              if (i === state.materialsForm.moduleIndex) {
                return {
                  ...mod,
                  medias: array.addNested(
                    mod.medias,
                    state.materialsForm.mediaIndex,
                    'materials',
                    action.payload
                  ),
                };
              }
              return mod;
            }),
          };
        }
        return {
          ...state,
          modules: array.addNested(
            state.modules,
            state.materialsForm.moduleIndex,
            'materials',
            action.payload
          ),
        };
      }
      return {
        ...state,
        medias: array.addNested(
          state.medias,
          state.materialsForm.mediaIndex,
          'materials',
          action.payload
        ),
      };
    case 'REMOVE_MATERIAL': {
      if (state.materialsForm.moduleIndex >= 0) {
        if (
          state.materialsForm.mediaIndex !== null &&
          state.materialsForm.mediaIndex >= 0
        ) {
          return {
            ...state,
            modules: state.modules.map((mod, i) => {
              if (i === state.materialsForm.moduleIndex) {
                return {
                  ...mod,
                  medias: array.removeNested(
                    mod.medias,
                    state.materialsForm.mediaIndex,
                    'materials',
                    action.payload.materialIndex
                  ),
                };
              }
              return mod;
            }),
          };
        }
        return {
          ...state,
          modules: array.removeNested(
            state.modules,
            state.materialsForm.moduleIndex,
            'materials',
            action.payload.materialIndex
          ),
        };
      }
      return {
        ...state,
        medias: array.removeNested(
          state.medias,
          state.materialsForm.mediaIndex,
          'materials',
          action.payload.materialIndex
        ),
      };
    }
    default:
      throw new Error('undefined playlist action');
  }
};

export const PlaylistContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PlaylistContext.Provider value={[state, dispatch]}>
      {props.children}
    </PlaylistContext.Provider>
  );
};
