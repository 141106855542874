import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PaymentApi from '../../../payment-api';
import PageTitle from '../../app/main/Title';

function UploadUsers() {
  const fileInputId = 'file-input-name';
  const [file, setFile] = useState(undefined);

  const uploadUsersFile = async (file) => {
    let formData = new FormData();
    formData.append('file', file);

    PaymentApi.post('/api/files/create_members', formData);
  };

  return (
    <>
      <PageTitle>Importação de novos membros</PageTitle>
      <p>
        Para iniciar uma ação de importação automática, carregue um arquivo csv
        com os dados dos novos membros seguindo o modelo definido{' '}
        <a
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/1Oq8rjN4WCalJYJDS3k9Xunk5NjDEeCCKivGXbosFeTk/edit?gid=0#gid=0"
          rel="noopener noreferrer"
        >
          {' '}
          neste template
        </a>
        .
      </p>

      <p>
	Requisitos para cada funcionalidade:<br/><br/>

	<b>1. Criação de novos membros com assinatura GBB</b><br/>
	- Usuário não deve existir na base com assinatura GBB ativa<br/>
	- Produto deve ser assinatura GBB<br/><br/>

	<b>2. Concessão de produto de Certificação para membros GBB ativos</b><br/>
	- Usuário precisa ser membro com assinatura GBB ativa<br/>
	- Produto deve ser Certificação<br/><br/>

	<b>3. Concessão de assinaturas promocionais para membros GBB ativos</b><br/>
	- Usuário deve ser membro com assinatura GBB ativa<br/>
	- Produto deve ser assinatura GBB promocional, ou seja, com data de expiração menor que a data de expiração do plano atual do membro<br/><br/>
      </p>

      <p>
	<b>Atenção:</b><br/>
	<i>
	  - Se o produto do usuário no arquivo for um GBB (supporter, good, better, best, mecenas) será executado a criação de novo membro (com envio de email de definição de senha)<br/>
	  - Se o produto for uma certificação (travessia, método-bp, etc) será executado a concessão de produto de certificação (que valida se o membro está ativo com GBB e não envia notificações).<br/>
	  - Limite de linhas: <b>10k</b>. Arquivos maiores podem causar a indisponibilidade do processador.
	</i>
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'space-between',
          padding: '1.5rem 0',
        }}
      >
        <input
          id={fileInputId}
          label="Arquivo CSV"
          type="file"
          accept=".csv"
          onChange={() =>
            setFile(document.getElementById(fileInputId).files[0])
          }
        ></input>

        <Button
          disabled={file === undefined}
          variant="contained"
          color="primary"
          onClick={() => uploadUsersFile(file)}
        >
          Iniciar importação
        </Button>
      </div>
    </>
  );
}

export default UploadUsers;
