import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ProductSelect from '../products/ProductSelect';
import UserSelect from '../users/UserSelect';

const customReasons = [
  'Afiliado',
  'Aluno de instituição parceira',
  'Membro fundador',
  'Funcionário',
  'Outro',
];

const PaymentSettingsGrid = ({ paymentSettings }) => {
  const { installments, payment_method, credit_card, pix, billet, nupay } = paymentSettings;

  return (
    <>
      <Grid item xs={12} md={1}>
	<TextField
          id="installments"
          label="Parcelas"
          value={installments}
          disabled
          readOnly
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
	/>
      </Grid>
      
      <Grid item xs={12} md={3}>
	<TextField
          id="payment_method"
          label="Método"
          value={payment_method}
          disabled
          readOnly
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
	/>
      </Grid>

      {credit_card && (
	<>
	  <Grid item xs={12} md={4}>
	    <TextField
	      id={`${credit_card.brand}`}
	      label="Bandeira"
	      value={credit_card.brand}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>

	  <Grid item xs={12} md={2}>
	    <TextField
	      id={`${credit_card.last_digits}`}
	      label="Últimos dígitos"
	      value={credit_card.last_digits}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>
	</>
      )}

      {nupay && (
	<Grid item xs={12} md={8}>
	  <TextField
	    id={`${nupay.tid}`}
	    label="Tid"
	    value={nupay.tid}
	    disabled
	    readOnly
	    fullWidth
	    margin="normal"
	    InputLabelProps={{
	      shrink: true,
	    }}
	  />
	</Grid>
      )}
      
      {billet && (
	<>
	  <Grid item xs={12} md={2}>
	    <TextField
	      id={`${billet.expiration_date}`}
	      label="Expiração"
	      value={billet.expiration_date}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>

	  <Grid item xs={12} md={3}>
	    <TextField
	      id={`${billet.line}`}
	      label="Linha"
	      value={billet.line}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>

	  <Grid item xs={12} md={3}>
	    <TextField
	      id={`${billet.url}`}
	      label="Url de pagamento"
	      value={billet.url}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>
	</>
      )}
      
      {pix && (
	<>
	  <Grid item xs={12} md={2}>
	    <TextField
	      id={`${pix.expiration_date}`}
	      label="Expiração"
	      value={pix.expiration_date}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>
	  
	  <Grid item xs={12} md={3}>
	    <TextField
	      id={`${pix.qrcode?.signature}`}
	      label="Assinatura"
	      value={pix.qrcode?.signature}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>

	  <Grid item xs={12} md={3}>
	    <TextField
	      id={`${pix.qrcode?.url}`}
	      label="Url de pagamento"
	      value={pix.qrcode?.url}
	      disabled
	      readOnly
	      fullWidth
	      margin="normal"
	      InputLabelProps={{
		shrink: true,
	      }}
	    />
	  </Grid>
	</>
      )}
    </>
  );
};

export default ({ subscription, handleSubmit }) => {
  const [formData, setFormData] = useState(subscription);
  const [customReason, setCustomReason] = useState('');
  const isManual = subscription.type === 'manual';
  useEffect(() => {
    if (isManual) {
      setCustomReason(subscription.reason);
    }
  }, [isManual, setCustomReason, subscription.reason]);

  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      ...formData,
      reason: isManual ? customReason || formData.reason : undefined,
    };
    
    if (isManual && !data.reason) {
      return alert('Por favor, informe o motivo da criação da assinatura');
    }
    
    if (!data.subscriptionPlan) {
      return alert('O produto da assinatura é obrigatório!');
    }
    
    handleSubmit(data)(event);
  };

  const isCustomReason =
    formData.reason &&
    (formData.reason === 'Outro' || !customReasons.includes(formData.reason));

  const productChange = (value) => {
    setFormData((old) => ({
      ...old,
      subscriptionPlan: value,
    }));
  };
  
  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
	  {!isManual && (
	    <Grid item xs={12} md={3}>
	      <KeyboardDatePicker
		disableToolbar
		variant="inline"
		format="dd/MM/yyyy"
		margin="normal"
		required
                label="Data de início"
                value={moment(formData.startedAt)}
		InputLabelProps={{
                  shrink: true,
		}}
		onChange={(date) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    startedAt: date,
                  }))
		}
		KeyboardButtonProps={{
                  'aria-label': 'change date',
		}}
              />
            </Grid>
          )}

          <Grid item xs={12} md={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              required
              label="Data de expiração"
              value={moment(formData.expires_in)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(date) =>
                setFormData((prevState) => ({
                  ...prevState,
                  expires_in: date,
                }))
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
	  
          <Grid item xs={12} md={3}>
            <UserSelect
              label="Dono da assinatura"
              disabled={isManual}
              readOnly={isManual}
              defaultValue={formData.owner.name}
              onChange={(owner) =>
                setFormData((prevState) => ({
                  ...prevState,
                  owner,
                }))
              }
            />
          </Grid>
	  
          {!isManual && (
            <Grid item xs={12} md={6}>
              <TextField
                id="gateway_code"
                label="Código externo"
                value={formData.gateway_code}
                disabled
                readOnly
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

	  <Grid item xs={12} md={2}>
            {isManual ? (
              <ProductSelect
                required
                value={formData.subscriptionPlan._id}
                onChange={productChange}
              />
            ) : (
              <TextField
                id="plan"
                label="Produto"
                value={formData.subscriptionPlan.name}
                disabled
                readOnly
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Grid>

	  {formData.payment_settings && <PaymentSettingsGrid paymentSettings={formData.payment_settings} />}
	  
          <Grid item xs={12} style={{ margin: '10px 0' }}>
            <Grid container spacing={2} alignItems="flex-end">
              {isManual && (
                <>
                  <Grid item>
                    <InputLabel id="reason-select-label">
                      Motivo do cadastro
                    </InputLabel>
                    <Select
                      labelId="reason-select-label"
                      id="reason-select"
                      name="reason"
                      style={{ width: 300 }}
                      value={isCustomReason ? 'Outro' : formData.reason || ''}
                      onChange={(event) => {
                        if (!isCustomReason) {
                          setCustomReason('');
                        }
                        setFormData((prevState) => ({
                          ...prevState,
                          reason: event.target.value,
                        }));
                      }}
                    >
                      {customReasons.map((reason) => (
                        <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
		  
                  {isCustomReason && (
                    <Grid item>
                      <FormControl style={{ width: '300px' }}>
                        <TextField
                          labelId="reason-custom-label"
                          id="reason-custom"
                          name="reason-custom"
                          required={isCustomReason}
                          label="Motivo personalizado"
                          value={customReason}
                          fullWidth
                          onChange={(event) => {
                            event.persist();
                            setCustomReason(event.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}

            </Grid>
          </Grid>
        </Grid>
	
        <Button type="submit" variant="contained" color="primary">
          {subscription._id ? 'atualizar' : 'cadastrar'}
        </Button>
      </form>
      
    </MuiPickersUtilsProvider>
  );
};
