import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  MenuList,
  Tooltip,
} from '@material-ui/core';
import { MenuBook, VisibilityOff } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPages } from '../../../integrations/api/pages';
import PageTitle from '../../app/main/Title';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  badge: {},
  pageCount: {
    opacity: 0.6,
    fontSize: '.7rem',
  },
  pageName: {
    marginRight: theme.spacing(1),
  },
}));

export default function PageList() {
  const [pages, setPages] = useState([]);
  const fetch = useCallback(() => getPages(), []);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetch().then((response) => {
      setPages(response.data);
    });
  }, [fetch]);

  return (
    <>
      <PageTitle>Páginas</PageTitle>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(`/admin/pages/create`)}
          >
            Nova Página
          </Button>
        </Grid>
      </Grid>
      <MenuList>
        {pages.length > 0 &&
          pages.map((page, index) => {
            return (
              <MenuItem
                key={page._id}
                onClick={() => {
                  history.push(`/admin/pages/${page._id}`);
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <MenuBook />
                      </Grid>
                      <Grid item>
                        <span className={classes.pageName}>{page.name}</span>
                        <span className={classes.pageCount}>
                          {page.categories.length} categorias
                        </span>
                      </Grid>
                      <Grid item>
                        {page.visible === false ? (
                          <Tooltip title="Invisível na plataforma">
                            <VisibilityOff />
                          </Tooltip>
                        ) : (
                          undefined
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
      </MenuList>
    </>
  );
}
