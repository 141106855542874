import { brokerProductActionTypes } from './brokerproduct.types';
import API from '../../api';
import PaymentApi from '../../payment-api';
import { setLoading } from '../loading/loading.actions';

export const setCurrentBrokerProduct = (brokerProduct) => ({
  type: brokerProductActionTypes.SET_CURRENT_BROKER_PRODUCT,
  payload: brokerProduct,
});

export const fetchBrokerProducts = () => {
  return {
    type: brokerProductActionTypes.FETCH_BROKER_PRODUCTS,
  };
};

export const receiveBrokerProducts = (payload) => {
  return {
    type: brokerProductActionTypes.RECEIVE_BROKER_PRODUCTS,
    payload,
  };
};

export const editBrokerProduct = (payload) => {
  return {
    type: brokerProductActionTypes.EDIT_BROKER_PRODUCT,
    payload,
  };
};

export const asyncFetchBrokerProducts = (params) => (dispatch) => {
  dispatch(fetchBrokerProducts);
  API.get('/broker_products', {
    params,
  })
    .then((response) => {
      dispatch(
        receiveBrokerProducts({
          data: response.data.docs,
          totalCount: response.data.totalDocs,
          page: response.data.page - 1,
          limit: response.data.limit,
        })
      );
    })
    .catch((err) => console.log(err));
};

export const asyncFetchBrokerProductById = (params) => (dispatch) => {
  dispatch(fetchBrokerProducts);
  API.get(`/broker_products/${params.id}`)
    .then((response) => dispatch(editBrokerProduct(response.data)))
    .catch((err) => console.log(err));
};

export const asyncUpdateBrokerProduct = (data) => (dispatch) => {
  PaymentApi.put(`/api/broker_product`, data)
    .then((response) => dispatch(editBrokerProduct(response)))
    .catch((error) => {
      console.log(error.response);
      alert('Não foi possível atualizar o produto.');
    })
    .finally(() => dispatch(setLoading(false)));
};

export const asyncCreateBrokerProduct = (data) => (dispatch) => {
  PaymentApi.post(`/api/broker_product`, data)
    .then((response) => dispatch(editBrokerProduct(response)))
    .catch((error) => {
      console.log(error);
      alert('Não foi possível criar o produto.');
    })
    .finally(() => dispatch(setLoading(false)));
};

export const asyncDeleteBrokerProduct = (data) => (dispatch) => {
  API.delete(`/broker_products/${data.id}`)
    .then((__) => dispatch(asyncFetchBrokerProducts()))
    .catch((error) => {
      console.log(error.response);
      alert('Não foi possível deletar o produto.');
    })
    .finally(() => dispatch(setLoading(false)));
};

export const asyncSynchronizeBrokerProductOffers = (brokerProductId) => (
  dispatch
) => {
  PaymentApi.post(`/api/broker_products/${brokerProductId}/sync_offers`, {})
    .catch((error) => {
      console.log(error.response);
    })
    .finally(() => dispatch(setLoading(false)));
};
