import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Email } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { debounceSearchRender } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { asyncFetchUsers, editUser } from '../../../redux/user/user.actions';
import DataTable from '../../table/DataTable';
import store from '../../../redux/store';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import ConfirmationDialog from './confirmationDialog';
import { sendResetPasswordToUser } from '../../../integrations/api/auth';

const UsersList = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.user.users);
  const page = useSelector((state) => state.user.page);
  const rowsPerPage = useSelector((state) => state.user.limit);
  const totalCount = useSelector((state) => state.user.totalCount);
  const [filter, setFilter] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [user2ResetPasswd, setUser2ResetPasswd] = useState({});

  useEffect(() => {
    dispatch(asyncFetchUsers({ page: 1, limit: 10, lean: true }));
  }, [dispatch]);

  const openConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(true);
  };

  const closeConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
  };

  const handleSendPasswordReset = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
    sendResetPasswordToUser({ email: user2ResetPasswd.email }).then(
      (response) => {
        let answer = {
          type: 'success',
          message: 'Solicitação de envio do e-mail realizada com sucesso',
        };
        if (response.status !== 200) {
          answer.type = 'error';
          answer.message = 'Falha ao enviar o e-mail';
        }
        store.dispatch(openSnackBar(answer));
      }
    );
  };

  const columns = [
    { name: '_id', label: ' ' },
    { name: 'name', label: 'Nome' },
    { name: 'email', label: 'Email' },
    { name: 'doc', label: 'Documento' },
    { name: 'auth', options: { display: false } },
  ];

  function onClickEditButton(rowDataId) {
    dispatch(editUser(null));
    history.push(`/admin/users/details/${rowDataId}`);
  }

  const options = {
    page: page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalCount,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => {
      setFilter(newFilter);
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUsers({
              filter: newFilter,
              page: 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onChangePage: (newPage) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUsers({
              filter: filter,
              page: newPage + 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onChangeRowsPerPage: (limit) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchUsers({
              filter: filter,
              page: 1,
              limit: limit,
              lean: true,
            })
          )
        );
      });
    },
    customRowRender: (data) => {
      const [_id, name, email, doc] = data;
      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 100 }}>
            <IconButton
              aria-label="Reenviar email"
              title="Reenviar email de redefinição de senha"
              component="span"
              onClick={(e) => {
                setUser2ResetPasswd({ _id: _id, name: name, email: email });
                openConfirmDialog(e);
              }}
            >
              <Email />
            </IconButton>

            <IconButton
              aria-label="upload picture"
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>

          <td align="left">{name.toUpperCase()}</td>
          <td style={{ width: 300 }} align="left">
            {email}
          </td>
          <td style={{ width: 160 }} align="rigth">
            {doc}
          </td>
        </tr>
      );
    },
  };

  return (
    <>
      <DataTable data={users} columns={columns} options={options} />

      {confirmDialog && (
        <ConfirmationDialog
          onCancel={closeConfirmDialog}
          onConfirm={handleSendPasswordReset}
          title="Envio de redefinição de senha"
          body={`Confirma o envio do e-mail de redefinição de senha à 
            ${user2ResetPasswd.name} (${user2ResetPasswd.email})?`}
          cancelText="Cancelar"
          confirmText="Enviar"
        />
      )}
    </>
  );
};

export default UsersList;
