import { filesActionTypes } from './files.types';

const INITIAL_STATE = {
  uploadedUsersFiles: {},
};

const filesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case filesActionTypes.FETCH_UPLOADED_USERS_FILES:
      return {
        ...state,
        isFetching: true,
      };
    case filesActionTypes.RECEIVE_UPLOADED_USERS_FILES:
      return {
        ...state,
        uploadedUsersFiles: {
          docs: action.payload.data,
          page: action.payload.page,
          limit: action.payload.limit,
          totalCount: action.payload.totalCount,
          isFetching: true,
        },
      };
    default:
      return state;
  }
};

export default filesReducer;
