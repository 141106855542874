import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';
import { PlaylistContext } from '../playlists/PlaylistContext';
import Form from './Form';
export default function Audio() {
  const [state, dispatch] = useContext(PlaylistContext);
  const handleClose = () =>
    dispatch({
      type: 'CLOSE_AUDIO',
    });
  return (
    <div>
      <Dialog
        open={state.audioForm.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Áudio do conteúdo</DialogTitle>
        <DialogContent>
          <Form />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
