import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PageTitle from '../../app/main/Title';
import ProductSelect from '../products/ProductSelect';
import ConfirmationDialog from './confirmationDialog';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core/';

import {
  asyncCreateBrokerProduct,
  asyncUpdateBrokerProduct,
} from '../../../redux/brokerproduct/brokerproduct.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '2rem 0',
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%',
    margin: 0,
  },
  editableField: {
    color: 'red',
  },
  copyBtn: {
    position: 'absolute',
    top: 18,
    right: 18,
  },
}));

const brokers = [{ value: 'guru', name: 'Guru' }];

export default ({ toUpdate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [oldBrokerProductData] = useState(
    toUpdate || {
      broker_product_id: '',
      gateway_name: '',
      description: '',
      broker_name: '',
      plan: {
	_id: '',
	name: '',
      },
      notCumulative: false,
      isActive: false,
    }
  );

  const [newBrokerProductData, setNewBrokerProductData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = (key, value) => {
    if (oldBrokerProductData[key] === value) {
      setNewBrokerProductData(_.omit(newBrokerProductData, [key]));
    } else {
      setNewBrokerProductData((brokerProduct) => ({
        ...brokerProduct,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    if (Object.keys(newBrokerProductData).length > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [newBrokerProductData, toUpdate]);

  const onProductChange = (value) => handleChange('plan', value);

  const keyToName = (key) => {
    const keyNames = {
      broker_product_id: 'Identificador',
      gateway_name: 'Nome',
      broker_name: 'Broker',
      plan: 'Produto',
      description: 'Descrição',
    };

    return keyNames[key] || key;
  };

  const openConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(true);
  };

  const closeConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog(false);

    if (toUpdate) {
      dispatch(
        asyncUpdateBrokerProduct({
          broker_product_id: oldBrokerProductData.broker_product_id,
          broker_name: oldBrokerProductData.broker_name,
          plan: oldBrokerProductData.plan._id,
          ...newBrokerProductData,
        })
      );
      setTimeout(() => window.location.reload(), 500);
    } else {
      dispatch(asyncCreateBrokerProduct(newBrokerProductData));
    }
  };

  return (
    <>
      <PageTitle>{toUpdate ? 'Atualizar' : 'Cadastrar'} produto</PageTitle>

      <form method="PUT" onSubmit={openConfirmDialog}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                className={classes.textField}
                label="Id do produto no broker"
                defaultValue={oldBrokerProductData.broker_product_id}
                name="broker_product_id"
                required={true}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <TextField
                className={classes.textField}
                label="Nome do produto no broker"
                defaultValue={oldBrokerProductData.gateway_name}
                name="gateway_name"
                variant="outlined"
                disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel>Broker*</InputLabel>
                <Select
                  defaultValue={oldBrokerProductData.broker_name}
                  name="broker_name"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  label="Broker*"
                  required={true}
                >
                  {brokers.map((b, idx) => (
                    <MenuItem key={`broker-name-${idx}`} value={b.value}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={3}>
              <ProductSelect
                onChange={onProductChange}
                value={oldBrokerProductData.plan?._id}
                className={classes.textField}
                required={true}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <TextField
                className={classes.textField}
                label="Descrição"
                defaultValue={oldBrokerProductData.description}
                variant="outlined"
                name="description"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
	    
	    <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="notCumulative"
                    defaultChecked={oldBrokerProductData.notCumulative}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Não cumulativa com outras promoções"
              />
            </Grid>
	    
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isActive"
                    defaultChecked={oldBrokerProductData.isActive}
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Ativa"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!canSubmit}
              >
                {toUpdate ? 'Atualizar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>

      {confirmDialog && (
        <ConfirmationDialog
          onCancel={closeConfirmDialog}
          onConfirm={handleSubmit}
          title={`Os seguintes campos serão ${
            toUpdate ? 'alterados' : 'cadastrados'
          }:`}
          body={Object.keys(newBrokerProductData)
            .map(keyToName)
            .join(', ')}
          cancelText="Cancelar"
          confirmText={toUpdate ? 'Atualizar' : 'Cadastrar'}
        />
      )}
    </>
  );
};
