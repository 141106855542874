import { Avatar, Button, Chip, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { debounceSearchRender } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../api';
import DataTable from '../../table/DataTable';

const useStyle = makeStyles((theme) => ({
  row: {
    '& td': {
      padding: theme.spacing(2),
    },
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const getSubscriptionGatewayLink = (gateway_code) => {
  const guruPattern = /sub_.*/;
  if (typeof gateway_code === 'string' && gateway_code.match(guruPattern)) {
    return `https://digitalmanager.guru/admin/subscriptions/${gateway_code}`;
  }
  return null;
};

const GatewayCode = ({ gateway_code }) => {
  const link = getSubscriptionGatewayLink(gateway_code);
  if (link) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        title="Ver na Guru"
      >
        {gateway_code}
      </a>
    );
  }
  return gateway_code;
};

export default function UserSubscriptions() {
  const classes = useStyle();
  const history = useHistory();
  const { id: userId } = useParams();

  const [subscriptions, setSubscriptions] = useState({});
  const [paramsOptions, setParamsOptions] = useState({ page: 0, limit: 10 });

  const fetchUserSubscriptions = useCallback(
    async (params) => {
      if (userId) {
        const response = await api.get(`/users/${userId}/subscriptions`, {
          params,
        });
        setSubscriptions(response.data);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchUserSubscriptions({ ...paramsOptions, page: paramsOptions.page + 1 });
  }, [userId, fetchUserSubscriptions, paramsOptions]);

  const columns = [
    { name: '_id', label: ' ' },
    { name: 'subscriptionPlan', label: 'Produto' },
    { name: 'gateway_code', label: 'Código externo' },
    { name: 'recurrency', label: 'Recorrência' },
    { name: 'expires_in', label: 'Data de expiração' },
    { name: 'createdBy', options: { display: false } },
  ];

  const options = {
    page: paramsOptions.page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: subscriptions.totalDocs,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: () => {},
    onChangePage: (newPage) =>
      setParamsOptions({ ...paramsOptions, page: newPage }),
    onChangeRowsPerPage: (limit) => {
      setParamsOptions({
        ...paramsOptions,
        page: 0,
        limit,
      });
    },
    customRowRender: (data) => {
      const [
        _id,
        subscriptionPlan,
        gateway_code,
        recurrency,
        expires_in,
        createdBy,
      ] = data;

      return (
        <tr
          key={_id}
          className={classes.row}
          style={{
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          }}
        >
          <td>
            <IconButton
              component="span"
              onClick={() =>
                history.push(`/admin/${userId}/subscriptions/${_id}`)
              }
            >
              <EditIcon />
            </IconButton>
          </td>
          <td className={classes.capitalize}>{subscriptionPlan?.name}</td>
          <td>{gateway_code && <GatewayCode gateway_code={gateway_code} />}</td>
          <td>
            {recurrency ||
              (createdBy?.name && (
                <Chip
                  avatar={<Avatar>{createdBy.name[0]?.toUpperCase()}</Avatar>}
                  label={`Criada por: ${createdBy.email}`}
                />
              ))}
          </td>
          <td>{moment(expires_in).format('DD/MM/YYYY')}</td>
        </tr>
      );
    },
  };

  return (
    <>
      <div style={{ textAlign: 'right', margin: `10px 0` }}>
        <Button
          color="primary"
          variant="contained"
          aria-label="add"
          href={`/admin/users/${userId}/subscriptions/create`}
          title="Cadastrar assinatura"
        >
          Nova assinatura
          <AddIcon />
        </Button>
      </div>

      <DataTable
        data={subscriptions?.docs}
        columns={columns}
        options={options}
      />
    </>
  );
}
