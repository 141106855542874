import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import SyncIcon from '@material-ui/icons/Sync';
import { debounceSearchRender } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  asyncFetchOffersByBrokerProductId,
  editOffer,
} from '../../../redux/offer/offer.actions';
import { openSnackBar } from '../../../redux/snackbar/snackbar.actions';
import PageTitle from '../../app/main/Title';
import DataTable from '../../table/DataTable';
import { getOfferSaleLink } from '../../../utils/offer';
import { asyncSynchronizeBrokerProductOffers } from '../../../redux/brokerproduct/brokerproduct.actions';

const BrokerOfferId = ({ brokerProductId, brokerOfferId, brokerName }) => {
  if (!brokerProductId) return null;
  if (brokerName === 'guru') {
    return (
      <a
        href={`https://digitalmanager.guru/admin/products/${brokerProductId}/offers/${brokerOfferId}`}
        target="_blank"
        rel="noopener noreferrer"
        title="Ver na Guru"
      >
        {brokerOfferId}
      </a>
    );
  }

  return brokerProductId;
};

export default function CustomPaginationActionsTable({ brokerProduct }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const offers = useSelector((state) => state.offer.offers);
  const page = useSelector((state) => state.offer.page);
  const rowsPerPage = useSelector((state) => state.offer.limit);
  const totalCount = useSelector((state) => state.offer.totalCount);
  const [filter, setFilter] = useState('');

  const { _id, broker_product_id } = brokerProduct;

  useEffect(
    () =>
      dispatch(
        asyncFetchOffersByBrokerProductId(_id, {
          page: 1,
          limit: 10,
          lean: true,
        })
      ),
    [dispatch, _id]
  );

  function onClickEditButton(rowDataId) {
    dispatch(editOffer(null));
    history.push(`/admin/offers/details/${rowDataId}`);
  }

  function onClickLinkButton(rowDataId, productName) {
    navigator.clipboard.writeText(getOfferSaleLink(rowDataId, productName));

    dispatch(
      openSnackBar({
        message: 'Link de checkout da oferta copiado!',
      })
    );
  }

  const columns = [
    { name: '_id', label: ' ' },
    // eslint-disable-next-line
    { name: '_link', label: ' ' },
    { name: 'name', label: 'Nome da Oferta' },
    { name: 'broker_offer_id', label: 'Id da oferta no broker' },
    { name: 'product', label: 'Produto' },
    { name: 'default', label: 'Padrão' },
    { name: 'broker_product_id', label: ' ' },
    { name: 'broker_name', label: ' ' },
  ];

  const options = {
    page: page,
    selectableRows: 'none',
    print: false,
    download: false,
    serverSide: true,
    count: totalCount,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    sort: false,
    filter: false,
    viewColumns: false,
    customSearchRender: debounceSearchRender(500),
    onSearchChange: (newFilter) => {
      setFilter(newFilter);
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(_id, {
              filter: newFilter,
              page: 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onRowsDelete: (data) => {
      alert('Não é possível deletar uma oferta.');
    },
    onChangePage: (newPage) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(_id, {
              filter: filter,
              page: newPage + 1,
              limit: rowsPerPage,
              lean: true,
            })
          )
        );
      });
    },
    onChangeRowsPerPage: (limit) => {
      new Promise((resolve) => {
        resolve(
          dispatch(
            asyncFetchOffersByBrokerProductId(_id, {
              filter: filter,
              page: 1,
              limit: limit,
            })
          )
        );
      });
    },
    customRowRender: (data) => {
      const [
        _id,
        // eslint-disable-next-line
        _link,
        name,
        broker_offer_id,
        product,
        defaultCheck,
        broker_product_id,
        broker_name,
      ] = data;
      // _link is a simple placeholder to prevent breaking the UI

      return (
        <tr
          key={_id}
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <td style={{ width: 5 }}>
            <IconButton
              component="span"
              onClick={() => {
                onClickEditButton(_id);
              }}
            >
              <EditIcon />
            </IconButton>
          </td>
          <td style={{ width: 5 }}>
            <IconButton
              component="span"
              onClick={() => {
                onClickLinkButton(_id, product?.name);
              }}
            >
              <LinkIcon />
            </IconButton>
          </td>
          <td style={{ padding: '0px 16px' }} align="left">
            {name}
          </td>
          <td style={{ padding: '0px 16px' }} align="left">
            <BrokerOfferId
              brokerProductId={broker_product_id}
              brokerOfferId={broker_offer_id}
              brokerName={broker_name}
            />
          </td>
          <td style={{ padding: '0px 16px' }} align="left">
            {product?.name}
          </td>
          <td style={{ width: 10 }} align="center">
            {defaultCheck ? (
              <input type="checkbox" readOnly checked />
            ) : (
              <input type="checkbox" readOnly checked={false} />
            )}
          </td>
        </tr>
      );
    },
  };

  function onClickSyncButton(brokerProductId) {
    if (!brokerProductId) {
      return;
    }

    dispatch(asyncSynchronizeBrokerProductOffers(brokerProductId));
  }

  return (
    <>
      <PageTitle>
        <>Ofertas do produto</>
        <IconButton
          component="span"
          hidden={!_id && true}
          onClick={() => onClickSyncButton(broker_product_id)}
        >
          <SyncIcon />
        </IconButton>
      </PageTitle>
      <DataTable data={offers} columns={columns} options={options} />
    </>
  );
}
