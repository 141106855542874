export default {
  entrevista: 'Entrevistas',
  curso: 'Núcleo de Formação',
  live: 'Lives',
  debate: 'Debates',
  podcast: 'Podcasts',
  serie: 'Originais BP',
  programa: 'Programas',
  canal: 'Canais',
  short: 'Pílulas',
  'bp-select': 'BP Select',
  'bp-kids': 'BP Kids',
  'sociedade-do-livro': 'Sociedade do livro',
  'escola-da-familia': 'Escola da família',
  'clube-da-musica': 'Clube da Música',
  travessia: 'Travessia',
  produto: 'Produtos',
};
