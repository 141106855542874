import api from '../../api';

export const getMainBanners = ({ page, limit }) =>
  api.get(`/banners/main?page=${page}&limit=${limit}`);

export const patchMainBanner = (banner) => {
  if (banner._id) return api.put(`/banners/main/${banner._id}`, banner);
  return api.post('/banners/main', banner);
};

export const getBanner = (id) => {
  return api.get(`/banners/${id}`);
};

export const getLastActiveMainBanners = (page) => {
  return api.get(
    `/banners/main/active?includeForeignVersion=true&includeFreeOnlyVersion=true&page=${page}`
  );
};
