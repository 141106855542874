import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import api from '../../../api';

export default ({
  onChange,
  value: defaultValue = '',
  className,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      api
        .get('/products')
        .then((r) => setProducts(r.data))
        .catch((err) => alert('Falha ao carregar lista de produtos.'));
    };

    fetchProducts();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel>Produto*</InputLabel>
      <Select
        defaultValue={value}
        fullWidth
        onChange={handleChange}
        label="Produto"
        required={props.required}
        disabled={props.disabled}
      >
        {products.map((p) => (
          <MenuItem key={p._id} value={p._id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
