import { gbbProducts, certProducts, appProducts } from './products';

/**
 * Generates offer sale links.
 * @param {ObjectId} offer_id
 * @returns {string} checkout link for the given offer.
 */
export const getOfferSaleLink = (offer_id, product_name) => {
  let productType = ''
  if (appProducts.includes(product_name)) {
    productType = 'teller';
  } else if (gbbProducts.includes(product_name)) {
    productType = 'gbb';
  }  else if (certProducts.includes(product_name)) {
    productType = 'certification';
  }
  
  return `https://checkout.brasilparalelo.com.br/${productType}?offer=${offer_id}`;
};
