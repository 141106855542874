import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../../api';
import Form from './Form';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { getUserById } from '../../../integrations/api/users';
import { Alert } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));
export default () => {
  const { userId } = useParams();
  const [subscription, setSubscription] = useState();
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const expires_in = new Date();
    expires_in.setDate(expires_in.getDate() + 365);
    getUserById(userId).then((user) => {
      setSubscription({
        subscriptionPlan: '',
        owner: user,
        expires_in,
        type: 'manual',
      });
    });
  }, [userId]);

  const handleSubmit = (data) => (e) => {
    e.preventDefault();
    data.owner = data.owner._id;
    api
      .post(`/admin/users/${userId}/subscriptions`, data)
      .then((res) => {
        history.push(`/admin/users/details/${userId}`);
        alert('Assinatura cadastrada com sucesso');
      })
      .catch((err) => alert('Falha ao cadastrar assinatura'));
  };
  return (
    <div className={classes.root}>
      {subscription && (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              to={`/admin/users/details/${subscription.owner._id}`}
            >
              {subscription.owner.name}
            </Link>
            <Typography color="textPrimary">Cadastrar assinatura</Typography>
          </Breadcrumbs>
          <br />
          <Alert severity="warning">
            {' '}
            Obs: não utilize o cadastro manual de assinaturas para resolver
            problemas de assinaturas pagas
          </Alert>
          <br />
	  <Form
            subscription={subscription}
            update={false}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  );
};
