import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import API from '../../../api';
const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-2} fill={stroke} fontSize={10} textAnchor="middle">
      R$ {value.toFixed(2).replace('.', ',')}
    </text>
  );
};
export default () => {
  const [transactions, setTransactions] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    API.get('/transactions/week')
      .then((res) => setTransactions(res.data))
      .catch((err) => console.log(err));
  }, [dispatch]);
  return transactions && transactions.length > 0 ? (
    <>
      <LineChart
        width={500}
        height={300}
        data={
          transactions &&
          transactions
            .sort((a, b) => {
              return new Date(a._id.data) - new Date(b._id.data);
            })
            .map((transaction) => ({
              data: moment(transaction._id.data).format('DD/MM'),
              total: transaction.total,
              quant: transaction.quant,
            }))
        }
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="data" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="total"
          label={<CustomizedLabel />}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="quant" stroke="#82ca9d" />
      </LineChart>
    </>
  ) : (
    <p> No transactions this week </p>
  );
};
