import { Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { getPageById, updatePage } from '../../../integrations/api/pages';
import PageTitle from '../../app/main/Title';
import AddCategory from './AddCategory';
import CategoryPanel from './CategoryPanel';

export default () => {
  const [page, setPage] = useState();
  const [expanded, setExpanded] = useState();
  const [dragDisabled, setDragDisabled] = useState(false);
  const { _id } = useParams();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const fetch = useCallback(() => getPageById(_id), [_id]);
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const records = reorder(
      page.categories,
      result.source.index,
      result.destination.index
    );
    updateCategories(records);
  };
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const updateCategory = useCallback((index, newData) => {
    setPage((page) => ({
      ...page,
      categories: page.categories.map((cat, cIndex) =>
        cIndex === index ? newData : cat
      ),
    }));
  }, []);
  const addCategory = (category) => {
    setPage((page) => ({
      ...page,
      categories: [...page.categories, { ...category, playlists: [] }],
    }));
  };
  const removeCategory = (categoryId) => {
    setPage((page) => ({
      ...page,
      categories: page.categories.filter((cat) => cat._id !== categoryId),
    }));
  };
  const updateCategories = (categories) => {
    setPage((page) => ({
      ...page,
      categories,
    }));
  };
  useEffect(() => {
    fetch().then((response) => setPage(response.data[0]));
  }, [fetch]);

  const onSubmit = async (event) => {
    event.preventDefault();
    updatePage(page._id, page);
  };

  let pageTypes = null;

  if (page) {
    pageTypes = [page.type];
    if (page.type === 'programa') {
      pageTypes = ['programa', 'canal'];
    } else if (page.type === 'bp-select') {
      pageTypes = ['bp-select', 'serie'];
    } else if (page.type === 'curso') {
      pageTypes = [
        'curso',
        'sociedade-do-livro',
        'escola-da-familia',
        'clube-da-musica',
      ];
    } else if (page.type === 'all') {
      pageTypes = [];
    }
  }

  return page ? (
    <>
      <PageTitle>{page.name}</PageTitle>
      {page?.name === 'Em Destaque' && (
        <p>
          Não renomear as categorias! A lógica do BFF para selecionar destaques
          depende do nome da categoria.
        </p>
      )}

      <form onSubmit={onSubmit}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Categorias:
            </Typography>
          </Grid>
          <Grid item>
            <AddCategory pageId={page._id} callback={addCategory} />
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={'droppable-categories'}>
            {(provided) => (
              <div ref={provided.innerRef}>
                {page.categories.map((category, rowIndex) => (
                  <Draggable
                    key={`draggable-cat-${rowIndex}`}
                    draggableId={`draggable-cat-${rowIndex}`}
                    isDragDisabled={dragDisabled}
                    index={rowIndex}
                    {...provided.droppableProps}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CategoryPanel
                          expanded={expanded}
                          setDisabledCategoryDrag={setDragDisabled}
                          handlePanelChange={handlePanelChange}
                          updateCategory={updateCategory}
                          removeCategory={removeCategory}
                          updateCategories={updateCategories}
                          key={category._id}
                          types={pageTypes}
                          // workaround to transform type in types, hence accepting "canal"
                          pageId={page._id}
                          data={category}
                          index={rowIndex}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div style={{ textAlign: 'right', marginTop: 20 }}>
          <Button type="submit" color="primary" variant="contained">
            Salvar
          </Button>
        </div>
      </form>
    </>
  ) : (
    <></>
  );
};
