import React, { useEffect, useState } from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { makeStyles } from '@material-ui/core/styles';
import { Edit, Reorder, Add, Delete } from '@material-ui/icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  Button,
  Grid,
  FormControl,
  Input,
} from '@material-ui/core';
import FormBuilder from '../form/FormBuilder';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const useConfirmDeletionStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  buttonGroup: {
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

const useTableActionsStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const useModalStyles = makeStyles((theme) => ({
  root: {
    minWidth: '1000px',
    minHeight: '600px',
  },
}));

const useStyles = makeStyles({
  table: {
    tableLayout: 'fixed',
  },
  draggableActive: {
    display: 'table',
  },
  tableCell: {
    width: '10%',
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function DndTable({
  title,
  data,
  columns,
  submitAdd,
  submitEdit,
  submitDelete,
  submitReordering,
  customActions,
  setModuleDragNotAllowed,
  singleRecordFormStructure,
  registerType = 'mídia',
}) {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const [initialState, setInitialState] = useState([]);
  const [tableData, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isReordering, setReordering] = useState(false);
  const [dialogContext, setDialogContext] = useState();
  const isDeletion = dialogContext && dialogContext.action === 'delete';

  useEffect(() => {
    setInitialState(data);
  }, [data]);
  useEffect(() => {
    setData(data);
  }, [data]);
  useEffect(() => {
    setModuleDragNotAllowed && setModuleDragNotAllowed(isReordering);
  }, [isReordering, setModuleDragNotAllowed]);

  const TableToolbar = () => {
    const classes = useToolbarStyles();

    const handleReordering = () => {
      setReordering(!isReordering);
    };

    const handleAdd = () => {
      setDialogContext({
        action: 'add',
        handler: submitAdd,
        record: {},
        position: tableData.length + 1,
      });
      setDialogOpen(true);
    };

    return (
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
        <Tooltip title={`Adicionar ${registerType}`}>
          <IconButton onClick={handleAdd}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Reordenar lista de ${registerType}`}>
          <IconButton disabled={isReordering} onClick={handleReordering}>
            <Reorder />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  };

  const TableActions = () => {
    const classes = useTableActionsStyles();

    return (
      <div style={{ visibility: isReordering ? 'visible' : 'hidden' }}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSaveReordering}
        >
          Salvar
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleDismissReordering}
        >
          Cancelar
        </Button>
      </div>
    );
  };

  function ActionButtons({ record, position }) {
    const actions = (
      <div>
        {customActions.map((action, index) => (
          <IconButton
            title={action.title}
            key={index}
            onClick={() => action.onClick(position)}
          >
            {action.icon()}
          </IconButton>
        ))}
        <IconButton onClick={handleEditRecord(record, position)}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleDeleteRecord(record, position)}>
          <Delete />
        </IconButton>
      </div>
    );
    return isReordering ? <Reorder /> : actions;
  }

  function DeletionConfirm({ record, deletionHandler, position }) {
    const classes = useConfirmDeletionStyles();

    const [confirmText, setConfirmText] = useState('');

    const verificationText = 'Quero excluir o registro';
    const recordVerificationName = record.name;
    const confirmPhrase = `${verificationText} ${recordVerificationName}`;
    const isConfirmed = confirmText === confirmPhrase;

    const handleDeletionChange = (event) => {
      const value = event.target.value;
      setConfirmText(value);
    };

    const handleConfirmDeletion = () => {
      setDialogOpen(false);
      deletionHandler(record, position);
    };

    return (
      <Paper>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleConfirmDeletion();
          }}
        >
          <div className={classes.root}>
            <Typography variant="subtitle1">
              Para confirmar, digite "{verificationText}{' '}
              <strong>{recordVerificationName}</strong>"
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <Input
                    id={`input-confirm-deletion`}
                    value={confirmText}
                    placeholder={confirmPhrase}
                    onChange={handleDeletionChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              type="submit"
              variant={'contained'}
              color={'secondary'}
              disabled={!isConfirmed}
            >
              Confirmar
            </Button>
          </div>
        </form>
      </Paper>
    );
  }

  const restoreInitialState = () => {
    setData(initialState);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const records = reorder(
      tableData,
      result.source.index,
      result.destination.index
    );
    setData(records);
  };

  const handleEditRecord = (record, position) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDialogContext({
      action: 'edit',
      handler: submitEdit,
      record,
      position,
    });
    setDialogOpen(true);
  };

  const handleDeleteRecord = (record, position) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDialogContext({
      action: 'delete',
      handler: submitDelete,
      record,
      position,
    });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogContext(undefined);
  };

  const handleSaveReordering = () => {
    submitReordering(tableData);
    setReordering(false);
  };

  const handleDismissReordering = () => {
    setReordering(false);
    restoreInitialState();
  };

  return (
    <Paper>
      <TableToolbar
        title={title}
        isReordering={isReordering}
        setReordering={setReordering}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key="header-cell-actions"
                align="left"
                padding="normal"
              >
                Ações
              </TableCell>

              {columns.map((headCell, index) => (
                <TableCell
                  key={`header-cell-${index}`}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  {headCell.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={'droppable-table'}>
              {(provided, snapshot) => (
                <TableBody ref={provided.innerRef}>
                  {tableData.map((record, rowIndex) => (
                    <Draggable
                      key={`draggable-${rowIndex}`}
                      draggableId={`draggable-${rowIndex}`}
                      isDragDisabled={!isReordering}
                      index={rowIndex}
                      {...provided.droppableProps}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          key={`row-${rowIndex}`}
                          ref={provided.innerRef}
                          className={
                            snapshot.isDragging
                              ? classes.draggableActive
                              : undefined
                          }
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell
                            key={`row-actions`}
                            component="td"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <ActionButtons
                              record={record}
                              position={rowIndex}
                            />
                          </TableCell>
                          {columns.map((column, columnIndex) => (
                            <TableCell
                              key={`row-${columnIndex}`}
                              component="td"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {record[column['field']]}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <TableActions
        isReordering={isReordering}
        handleSaveReordering={handleSaveReordering}
        handleDismissReordering={handleDismissReordering}
      />
      {dialogContext && (
        <Dialog
          className={modalClasses.root}
          onClose={handleDialogClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="lg"
          fullWidth={true}
          open={dialogOpen}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
            {isDeletion ? 'Exclusão' : 'Edição'} de {registerType}
          </DialogTitle>
          <DialogContent dividers>
            {isDeletion ? (
              <DialogContent dividers>
                <DeletionConfirm
                  record={dialogContext.record}
                  position={dialogContext.position}
                  deletionHandler={dialogContext.handler}
                />
              </DialogContent>
            ) : (
              <FormBuilder
                data={dialogContext.record}
                structure={singleRecordFormStructure}
                onSubmit={(recordState) => {
                  setDialogOpen(false);
                  dialogContext.handler(recordState, dialogContext.position);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </Paper>
  );
}
