import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavContext } from '../navigation/NavContext';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function MenuItem(props) {
  let subItems = [];
  let subItemsLink = [];
  let expandIcon;
  let collapseItem;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [, setNavData] = useNavContext();

  const handleClick = () => {
    setNavData(props);
    if (props.subItems) {
      setOpen(!open);
    }
  };

  function getTruncateLocation() {
    return location.pathname
      .split('/')
      .slice(0, 3)
      .join('/');
  }

  if (props.subItems) {
    props.subItems.forEach((subItem, index) => {
      subItemsLink.push(subItem.link);

      subItems.push(
        <ListItem
          button
          key={index}
          selected={location.pathname.includes(subItem.link)}
          className={props.classes && props.classes.nested}
          component={Link}
          to={subItem.link}
          onClick={() => setNavData(subItem)}
        >
          {subItem.icon && <ListItemIcon>{<subItem.icon />}</ListItemIcon>}
          <ListItemText primary={subItem.text} />
        </ListItem>
      );
    });

    expandIcon =
      subItemsLink.includes(getTruncateLocation()) || open ? (
        <ExpandLess />
      ) : (
        <ExpandMore />
      );

    collapseItem = (
      <Collapse
        in={subItemsLink.includes(getTruncateLocation()) || open}
        timeout="auto"
        unmountOnExit
        className={classes.nested}
      >
        <List component="div">{subItems}</List>
      </Collapse>
    );
  }

  return (
    <>
      <ListItem
        selected={location.pathname.includes(props.link)}
        button
        key={props.text}
        component={Link}
        to={props.link}
        onClick={handleClick}
      >
        {props.icon && <ListItemIcon>{<props.icon />}</ListItemIcon>}
        <ListItemText primary={props.text} />
        {expandIcon}
      </ListItem>

      {collapseItem}
    </>
  );
}
