import Button from '@material-ui/core/Button';
import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function Logout() {
  const dispatch = useDispatch();

  const onSuccess = () => {
    localStorage.clear();
    dispatch(setCurrentUser()); //Clear current user
  };

  const onFailure = (res) => {
    console.log('[Logout failed] res:', res);
  };
  return (
    <div>
      <GoogleLogout
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            color="inherit"
          >
            Logout
            <ExitToAppIcon />
          </Button>
        )}
        onLogoutSuccess={onSuccess}
        onFailure={onFailure}
      />
    </div>
  );
}

export default Logout;
